import React from 'react'
import { useLaunchParams } from '@telegram-apps/sdk-react'
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Card, CardContent } from "@/components/ui/card"
import { Battery, Gem, Plus, HelpCircle, Globe } from 'lucide-react'

export default function GameHeader() {
  const { initData } = useLaunchParams()

  const userName = initData?.user?.firstName || 'Player'

  return (
    <Card className="w-full bg-transparent text-white">
      <CardContent className="p-2">
        <div className="flex items-center justify-between rounded-lg ml-2">


        {/* left side */}
        <div className="flex items-center space-x-2 bg-transparent rounded-xl p-1 pl-0">
          {/* Avatar component with fallback */}
          <Avatar className="w-8 h-8 border-2 border-emerald-500">
            <AvatarImage src="/av.jpg" alt={userName} />
            <AvatarFallback>{userName.slice(0, 2).toUpperCase()}</AvatarFallback>
          </Avatar>
          <div className="flex flex-row items-center">
            {/* TODO: ADD REAL VARIABLE FOR NAME */}
            <span className="text-sm font-semibold truncate max-w-[150px]">{userName}</span>
            <div className="flex items-end space-x-2 flex-col pr-1">
              {/* Battery icon with text */}
              <div className="flex items-center">
                <Battery className="w-2 h-2 text-blue-500 mr-1" />
                <span className="text-xs text-blue-500">0</span>
              </div>
              {/* Gem icon with text */}
              <div className="flex items-center">
                <Gem className="w-2 h-2 text-purple-500 mr-1" />
                <span className="text-xs text-purple-500">0</span>
              </div>
            </div>
          </div>
        </div>
        {/* right side */}
        <div className="flex items-center space-x-1 sm:space-x-2">
            {/* Tooltip for adding resources */}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button size="icon" variant="ghost" className="h-6 w-6 sm:h-8 sm:w-8 bg-gray-800 rounded-full p-0.5">
                    <Plus className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Add resources</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            {/* Dropdown menu for settings */}
            {/* <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size="icon" variant="ghost" className="h-6 w-6 sm:h-8 sm:w-8 bg-gray-800 rounded-full p-0.5">
                  <Settings className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem>Profile</DropdownMenuItem>
                <DropdownMenuItem>Settings</DropdownMenuItem>
                <DropdownMenuItem>Logout</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu> */}
            {/* Tooltip for help */}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button size="icon" variant="ghost" className="h-6 w-6 sm:h-8 sm:w-8 bg-gray-800 rounded-full p-0.5">
                    <HelpCircle className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Help</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            {/* Dropdown menu for language selection */}
            {/* <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size="icon" variant="ghost" className="h-6 w-6 sm:h-8 sm:w-8 bg-gray-800 rounded-full p-0.5">
                  <Globe className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem>English</DropdownMenuItem>
                <DropdownMenuItem>Español</DropdownMenuItem>
                <DropdownMenuItem>Français</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu> */}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}