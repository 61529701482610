import React, { useEffect, useRef, useState } from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import { motion, AnimatePresence } from "framer-motion"
import { Card, CardContent } from "@/components/ui/card"
import SplineScene from './SplineScene';
import BubblyButton from './BubblyButton';
import { PlugZap, PackagePlus, BatteryCharging, Zap, Smartphone, Share } from 'lucide-react';
import { Button } from "@/components/ui/button"

// Remove the following line:
// import { WebApp } from '@telegram-apps/sdk-react';

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full"
    {...props}
  />
))
Avatar.displayName = AvatarPrimitive.Root.displayName

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className="aspect-square h-full w-full"
    {...props}
  />
))
AvatarImage.displayName = AvatarPrimitive.Image.displayName

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className="flex h-full w-full items-center justify-center rounded-full bg-gray-700"
    {...props}
  />
))
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName

export function CryptoGameUi() {
  const [score, setBalance] = useState(1)
  const [energy, setEnergy] = useState(1)
  const [plusTens, setPlusTens] = useState<Array<{ id: number; x: number; y: number; rotation: number }>>([])
  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now())
  const [dailyIncome, setDailyIncomeLimit] = useState(140400)
  const [boostTime, setBoostTime] = useState(7200)
  const [claimTime, setClaimTime] = useState(28477)
  const [unclaimedPoints, setUnclaimedPoints] = useState(0)

  const formatNumber = (num: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short',      minimumFractionDigits: num < 100000 ? 0 : 1,       maximumFractionDigits: num < 100000 ? 0 : 3
    });
    return formatter.format(num);
  };

  const handleTap = () => {
    const currentTime = Date.now()
    if (currentTime - lastUpdateTime > 50) {
      setUnclaimedPoints(prevPoints => prevPoints + 1)
      setEnergy(prevEnergy => prevEnergy + 1)
      setLastUpdateTime(currentTime)

      const newPlusTen = {
        id: currentTime,
        x: (Math.random() - 0.5) * 150,
        y: (Math.random() - 0.5) * 150,
        rotation: Math.random() * 360,
      }
      setPlusTens(current => [...current, newPlusTen])
      setTimeout(() => {
        setPlusTens(current => current.filter(pt => pt.id !== newPlusTen.id))
      }, 1500)
    }
  }

  const handleBoost = () => {
    console.log("Boost activated")
    setBoostTime(prevTime => prevTime + 3600)
    setDailyIncomeLimit(prevIncome => prevIncome * 1.5)
  }

  const claimPoints = () => {
    console.log("Package opened")
    setBalance(prevScore => prevScore + unclaimedPoints)
    setEnergy(0)
    setDailyIncomeLimit(prevIncome => Math.max(0, prevIncome - unclaimedPoints))
    setUnclaimedPoints(0)
  }

  const handleInvite = () => {
    const shareUrl = 'https://t.me/ZenFoxes_bot';
    const shareText = 'Join me in ZenFoxes! Tap to earn coins, boost your score, and climb the leaderboard. Prepare for season one!';
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`;

    window.open(telegramShareUrl, '_blank');
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setBoostTime(prevTime => Math.max(0, prevTime - 1))
      setClaimTime(prevTime => Math.max(0, prevTime - 1))
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60
    return `${hours}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
  }

  return (
    <div className='overflow-hidden'>
      {/* zindex -10 */}
      <div className="absolute top-0 left-0 w-full h-full -z-10 overflow-hidden pointer-events-none user-select-none after:content-[''] after:absolute after:inset-0" id="bg-overlay">
        <div className='shiny-overlay' />
        <SplineScene />
      </div>
      {/* Main Content */}
      <main className="flex-1 p-4 pl-0 pr-0 pt-0 overflow-hidden w-full">
        <Card className="bg-transparent border-transparent mb-4">
          <CardContent className="p-4 pb-0">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-gray-400">Daily Income</p>
                <p className="text-xl font-bold"><span className="text-purple-400">⚡</span> {formatNumber(dailyIncome)}</p>
              </div>
              <div className="text-right">
                <p className="text-gray-400">Balance</p>
                <motion.p
                  key={score}
                  initial={{ opacity: 0.9 }}
                  animate={{ opacity: 1 }}
                  className="text-xl font-bold text-yellow-400" 
                >
                  {formatNumber(score)} <span className="text-xs">$</span>
                </motion.p>
              </div>
            </div>
          </CardContent>
        </Card>
        {/*  PointsCounter   */}
        <div className="flex flex-col items-center justify-center w-full mb-12">
          <div className="flex gap-8">
               <div className="w-12 h-12 bg-yellow-400 rounded-full flex items-center justify-center">
               <span className="text-3xl font-bold text-yellow-800">$</span>
               </div>
               <div className={`relative ${energy < 10 ? 'w-16' :
                energy < 100 ? 'w-16' :
                  energy < 1000 ? 'w-24' :
                    energy < 10000 ? 'w-36' :
                      energy < 100000 ? 'w-42' :
                        energy < 1000000 ? 'w-56' :
                          'w-64'
              } h-12 flex items-center`}>
              <AnimatePresence mode="sync">
                <motion.div
                  key={energy}
                  initial={{ opacity: 0, y: 2 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                  className="left-0 text-5xl font-bold text-yellow-500"
                >
                  {energy > 9999999 ? formatNumber(Math.floor(energy)) : energy > 9999999
                    ? energy.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : Math.floor(energy).toLocaleString()}
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
        {/* PointsCounter END  */}
        <div className="flex justify-center">
          <motion.div
            className="relative w-64 h-100"
            onClick={handleTap}
          >
            <img
              src="/character-2.png?v=213"
              alt="ZenFox"
              className="w-full h-full object-contain"
            />
            <div className="absolute inset-0" />
            <AnimatePresence>
              {plusTens.map((pt) => (
                <motion.div
                  key={pt.id}
                  className="absolute top-1/2 left-1/2 text-green-500 font-semibold text-3xl -z-10"
                  initial={{ opacity: 0, scale: 0.5, x: '-50%', y: '-50%', rotate: 0 }}
                  animate={{
                    opacity: [0, 1, 1, 0],
                    scale: [0.5, 2, 4, 18.5],
                    x: `calc(-50% + ${pt.x}px)`,
                    y: `calc(-50% + ${pt.y}px)`,
                    rotate: [0, pt.rotation, pt.rotation + 45]
                  }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  transition={{ duration: 1.5, times: [0, 0.2, 0.8, 1] }}
                  style={
                    {
                      userSelect: 'none',
                      pointerEvents: 'none',
                    }
                  }
                >
            {energy > 100 ? <img src="/coin.webp" alt="Coin" className="w-6 h-6" /> : "+1"}
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        </div>
        
        {/* UNDER CHARACTER CARD */}
        <Card className="w-full mx-auto bg-transparent relative overflow-hidden p-4 rounded-xl -mt-12">
          {/* Boost Timer */}
          <div className="absolute top-2 left-2 text-white px-2 py-1 rounded-lg z-10 flex items-center space-x-1">
            <Zap className="w-4 h-4 text-yellow-400 mr-1" />
            <span className="text-xs font-semibold">Boost {formatTime(boostTime)}</span>
          </div>

          <div className="mt-8 flex space-x-2 items-center">
            {/* Main Claim Area - Primary Action */}
            <div className="bg-blue-500 rounded-full h-16 flex-grow flex items-center justify-between px-4">
              <div className="flex items-center">
                <div className="bg-blue-600 rounded-full p-2">
                  <span className="text-sm font-bold text-white">{formatTime(claimTime)}</span>
                </div>
              </div>
              <div className="text-xl font-bold flex-grow flex items-center justify-center !cursor-pointer"
                onClick={handleTap}
              >
                <span className="text-white">TAP</span>
              </div>
              <div className="invisible">
                <div className="bg-blue-600 rounded-full p-2">
                  <span className="text-sm font-bold text-white">{formatTime(claimTime)}</span>
                </div>
              </div>
            </div>

            {/* Package Button - Secondary Action */}
            <Button className="bg-green-500 text-white p-3 rounded-full h-16 w-16 hover:bg-green-600" onClick={claimPoints}>
              <PackagePlus className="h-6 w-6" />
            </Button>
          </div>

          {/* Bottom Controls */}
          <div className="mt-4 flex justify-between items-center">
            {/* Boost Button - Tertiary Action */}
            <div className='flex flex-col-reverse items-center'>
              <div className="text-xs text-gray-300 mt-2">
                Boost
              </div>
              <Button className="bg-purple-500 text-white p-3 rounded-full h-12 w-12 hover:bg-purple-600 flex items-center justify-center" onClick={handleBoost}>
                <PlugZap className="h-6 w-6" />
              </Button>
            </div>
            
            {/* Invite Button */}
            <div className='flex flex-col-reverse items-center'>
              <div className="text-xs text-gray-300 mt-2">
                Invite
              </div>
              <Button className="bg-orange-500 text-white p-3 rounded-full h-12 w-12 hover:bg-orange-600 flex items-center justify-center" onClick={handleInvite}>
                <Share className="h-6 w-6" />
              </Button>
            </div>
          </div>
          
        </Card>
      </main>
    </div>
  )
}

