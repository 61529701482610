    import React from 'react';
    import { FallbackProps } from 'react-error-boundary';
    import { useLanguage } from '@/utils/LanguageContext';
    import { Text, Button, Section } from '@telegram-apps/telegram-ui';
    
    const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
    	const { t } = useLanguage();
		console.log(error);
    	return (
    		<Section
    			style={{
    				padding: '40px 0px',
    				display: 'flex',
    				flexDirection: 'column',
    				alignItems: 'center',
    				justifyContent: 'center',
    				minHeight: '100vh',
    				backgroundColor: '#232e3c', // Dark background for game UI
    			}}
    		>
    			<Text style={{ fontSize: '28px', fontWeight: 'bold', color: '#ff4757', marginBottom: '20px' }}>
    				{t('error.unexpected')}
    			</Text>
    			<Text
    				style={{
    					color: '#ffffff',
    					opacity: 0.8,
    					marginBottom: '30px',
    					textAlign: 'center',
    					maxWidth: '300px',
    				}}
    			>
    				{/* {error.message} // dont show error message to user */}
					Please try again later.
    			</Text>
    			<Button
    				onClick={resetErrorBoundary}
    				style={{
    					marginTop: '20px',
    					minWidth: '140px',
    					padding: '14px 28px',
    					fontSize: '18px',
    					color: '#ffffff',
    					border: 'none',
    					borderRadius: '12px',
    					cursor: 'pointer',
    					transition: 'transform 0.2s ease',
    					boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
    				}}
    				onMouseDown={(e) => e.currentTarget.style.transform = 'scale(0.95)'}
    				onMouseUp={(e) => e.currentTarget.style.transform = 'scale(1)'}
    			>
    				{t('common.retry')}
    			</Button>
    		</Section>
    	);
    };
    
    export default ErrorFallback;
