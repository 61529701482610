import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { useState, useEffect } from 'react';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function useHoverEffect(id: string) {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const handleHover = (event: CustomEvent) => {
      if (event.detail.id === id) {
        setHovered(event.detail.hovered);
      }
    };

    window.addEventListener('hover-effect', handleHover as EventListener);

    return () => {
      window.removeEventListener('hover-effect', handleHover as EventListener);
    };
  }, [id]);

  const itemProps = {
    onMouseEnter: () => {
      window.dispatchEvent(new CustomEvent('hover-effect', { detail: { id, hovered: true } }));
    },
    onMouseLeave: () => {
      window.dispatchEvent(new CustomEvent('hover-effect', { detail: { id, hovered: false } }));
    }
  };

  return { hovered, itemProps };
}
